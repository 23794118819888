/**
 * Style on the standard burger navigation menu of luigi
 */

/**
 * Add tenant to top navigation
 */
$.when($.ready).then(function() {
    // set default config for notify plugin
    $.notify.defaults({
        autoHideDelay: 9000,
        position: 'top center',
    })

    function pushNotificationAlert(msg, type) {
        // showing notifications
        Luigi.ux().showAlert({
            type: type,
            closeAfter: 5000,
            text: msg,
        })
    }

    // Listing Module message listener
    window.addEventListener('message', function(e) {
        if (e.data[0] && e.data[0] === 'notificationManager') {
            //console.log('notification message== ' + e.data[1]);
            const msgObj = JSON.parse(e.data[1])
            if (msgObj.data) {
                const notificationMsg = msgObj.data.message
                // show different notification messages
                if (msgObj.msg === 'showSuccess') {
                    // $.notify(notificationMsg, "success");
                    pushNotificationAlert(notificationMsg, 'success')
                }
                if (msgObj.msg === 'showInfo') {
                    // $.notify(notificationMsg, "info");
                    pushNotificationAlert(notificationMsg, 'info')
                }
                if (msgObj.msg === 'showWarning') {
                    $.notify(notificationMsg, 'warn')
                    pushNotificationAlert(notificationMsg, 'warn')
                }
                if (msgObj.msg === 'addNotification') {
                    if (msgObj.data.level === 'alert-success') {
                        pushNotificationAlert(notificationMsg, 'success')
                    } else if (
                        msgObj.data.level === 'alert-danger' ||
                        msgObj.data.level === 'alert-warning'
                    ) {
                        // $.notify(notificationMsg,"warn");
                        pushNotificationAlert(notificationMsg, 'warn')
                    } else if (msgObj.data.level === 'alert-info') {
                        // $.notify(notificationMsg, "info");
                        pushNotificationAlert(notificationMsg, 'info')
                    } else {
                        // $.notify(notificationMsg, "warn");
                        pushNotificationAlert(notificationMsg, 'warn')
                    }
                }
                if (msgObj.msg === 'showError') {
                    // $.notify(notificationMsg, "error");
                    pushNotificationAlert(notificationMsg, 'error')
                }
            }
        }
    })
})
